.card-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

h4{
    color: #BDD990;
    text-align: center;
}

.card{
    width: 44%;
    margin: 0px;
    margin-top: 25px;
    width: 45%;
    height: auto;
    background-color: #5E5759;
    outline: solid #BDD990;
    margin-bottom: 70px;
}
.img-container{
    display: flex;
    justify-content: space-evenly;
    background-color: black;
    height: 200px;
}
.project-img{
    width: auto;
    height: 100%;
    border-radius: 13px;
    overflow: hidden;
    padding: 10px;
}

.sections-container{
    display: flex;
    justify-content: space-between;
    height: 300px;
    width: auto;
}

.left-card-section{
    height: 100%;
    width: 284%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.project-title{
    font-size: 4rem;
    text-align: center;
    color: #BDD990;
}

.right-card-section{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 210%;
}

.project-description, .project-tech{
    height: 16rem;
    overflow: scroll;
}

@media only screen and (max-width: 1024px){
    .img-container{
        height: 150px;
    }
}

@media only screen and (max-width: 768px){
    .card{
        width: 100%;
    }
    .card-container{
        flex-direction: column;
    }
}

@media only screen and (max-width: 425px){

}

@media only screen and (max-width: 320px){
 
}