.body{
    padding: 0%  5%;
    padding-top: 45px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background-color: #22181c;
    color: #FFFFFA;
    font-family: monospace;
}
h1{
    font-family: monospace;
}

.paragraph{
    text-align: left;
    padding: 10px;
}

.paragraph-one{
    width: 75%;
}

.concept-list, .learning-list {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.li-list{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;   
}

.hero-image{
    width: 100%;
    padding: 6px;
}

.titles{
    display: flex;
    flex-direction: column;
    align-content: space-between;
}

.titles-and-my-self-img{
    display: flex;
    flex-direction: row;
    height: 80px;
    justify-content: space-between;
}

#self-img{
    max-width: 45%;
    height: auto;
    padding: 6px;
    margin: 15px 0px;
    float: right;
    outline: solid #BDD990;
    background-color: #5E5759;
}

@media only screen and (max-width: 1440px){
    #self-img{
        max-width: 60%;
    }

}

@media only screen and (max-width: 1024px){
    .paragraph-one {
        width: 67%;
    }
    #self-img{
        max-width: 62%;
    }

}

@media only screen and (max-width: 768px){
    .paragraph-one {
        width: 68%;
    }
    #self-img{
        max-width: 50%;
    }
}

@media only screen and (max-width: 425px){
    #self-img{
        max-width: 70%;
    }
    .paragraph-one {
        width: 100%;
    }
    .titles-and-my-self-img{
        height: 130px;
    }

}

@media only screen and (max-width: 320px){
    #self-img{
        max-width: 80%;
    }
}
a {
    color:#FFFFFA
}