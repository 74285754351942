.media-container{
    width: 100%;
    height: auto;
    background-color: #5E5759;
    outline: solid #BDD990
}

.background-and-border{
    width: 100%;
    height: auto;
    padding: 1px 6px;
    margin-top: 5px;
}

.video{
    height: 730px;
    background-color: #5E5759;
    outline: solid #BDD990;
    padding: 4px 6px;

}
@media only screen and (max-width: 1024px){
    .video{
        height: 523px;
    }
}
@media only screen and (max-width: 768px){
    .video{
        height: 395px;
    }
}
@media only screen and (max-width: 425px){
    .video{
        height: 220px;
        padding: 4px 4px;
    }
}
@media only screen and (max-width: 375px){
    .video{
        height: 194px;
    }
}
@media only screen and (max-width: 320px){
    .video{
        height: 165px;
    }
}