@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


.title{
    left: 50px;
    color: #BDD990;
    text-align: left;
}
.header-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-bottom: 1px solid #BDD990;
    height: 100px;
    width: 100%;
    padding: 0 5%;
    position: fixed;
    background-color: #22181C;
}
.nav-container{
    display: flex;
    justify-content: flex-end;
    text-align: right;
    color: aliceblue;
    padding: 0;
}

a:focus, a:hover {
    color:  #BDD990;
    text-decoration: none;
}
.small-menu, .large-menu {
    background-color: #22181b;
    border-bottom: solid 1px #bcd990;
}
ul{
    list-style: none;
    margin-bottom: 0;
}

#nm-logo{
    height: 199px;
    margin: -38px -54px;
    padding: 0;
}

.a-text{ 
    display: flex;
    justify-content: space-evenly;
    margin: 28px 0px 18px 2px;
    padding: 12px 0 0 0px;
    font-size: 1.8rem;
    text-decoration: none;
    color: white;
    background-color: #5e5759;
    width: 100px;
    height: 50px;
    border: solid 1px #BDD990;
    font-family: monospace;
}
.Navbar{
    display: flex;
    justify-content: space-between;
    padding: 0 5%;
}
.ntmdev-logo{
    font-size: 6rem;
    font-family: monospace;
    padding-top: 8px;
    color: #BDD990;
}

@media only screen and (max-width: 500px){
    #nm-logo{
        height: 54px;
    }
    .header-container{
        height: 70px;
        height: 70px;
        justify-content: center;
        padding: 0;
    }
    .a-text{
        width: 300px;
        margin: 5px;
    }
    .nav-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: right;
        padding: 2% 2%;
        color: aliceblue;
        background: #22181b;
        outline: solid 1px #bcd990;
    }
    .manu-container{
        width: 100%;
    }
    .Navbar{
        display: flex;
        flex-direction: column;
    }
}
@media only screen and (max-width: 900px){
    .ntmdev-logo {
        font-size: 4rem;
        font-family: monospace;
        padding-top: 20px;
    }
}
.body{
    padding: 0%  5%;
    padding-top: 45px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background-color: #22181c;
    color: #FFFFFA;
    font-family: monospace;
}
h1{
    font-family: monospace;
}

.paragraph{
    text-align: left;
    padding: 10px;
}

.paragraph-one{
    width: 75%;
}

.concept-list, .learning-list {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.li-list{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;   
}

.hero-image{
    width: 100%;
    padding: 6px;
}

.titles{
    display: flex;
    flex-direction: column;
    align-content: space-between;
}

.titles-and-my-self-img{
    display: flex;
    flex-direction: row;
    height: 80px;
    justify-content: space-between;
}

#self-img{
    max-width: 45%;
    height: auto;
    padding: 6px;
    margin: 15px 0px;
    float: right;
    outline: solid #BDD990;
    background-color: #5E5759;
}

@media only screen and (max-width: 1440px){
    #self-img{
        max-width: 60%;
    }

}

@media only screen and (max-width: 1024px){
    .paragraph-one {
        width: 67%;
    }
    #self-img{
        max-width: 62%;
    }

}

@media only screen and (max-width: 768px){
    .paragraph-one {
        width: 68%;
    }
    #self-img{
        max-width: 50%;
    }
}

@media only screen and (max-width: 425px){
    #self-img{
        max-width: 70%;
    }
    .paragraph-one {
        width: 100%;
    }
    .titles-and-my-self-img{
        height: 130px;
    }

}

@media only screen and (max-width: 320px){
    #self-img{
        max-width: 80%;
    }
}
a {
    color:#FFFFFA
}
.card-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

h4{
    color: #BDD990;
    text-align: center;
}

.card{
    width: 44%;
    margin: 0px;
    margin-top: 25px;
    width: 45%;
    height: auto;
    background-color: #5E5759;
    outline: solid #BDD990;
    margin-bottom: 70px;
}
.img-container{
    display: flex;
    justify-content: space-evenly;
    background-color: black;
    height: 200px;
}
.project-img{
    width: auto;
    height: 100%;
    border-radius: 13px;
    overflow: hidden;
    padding: 10px;
}

.sections-container{
    display: flex;
    justify-content: space-between;
    height: 300px;
    width: auto;
}

.left-card-section{
    height: 100%;
    width: 284%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.project-title{
    font-size: 4rem;
    text-align: center;
    color: #BDD990;
}

.right-card-section{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 210%;
}

.project-description, .project-tech{
    height: 16rem;
    overflow: scroll;
}

@media only screen and (max-width: 1024px){
    .img-container{
        height: 150px;
    }
}

@media only screen and (max-width: 768px){
    .card{
        width: 100%;
    }
    .card-container{
        flex-direction: column;
    }
}

@media only screen and (max-width: 425px){

}

@media only screen and (max-width: 320px){
 
}
.construction-text{
    font-family: monospace;
    color: #BDD990;
}

.icon-cluster{
    display: flex;
    justify-content: center;
}

.icon{
    width: 100%;
    height: auto;
    padding: 25px;
}

.construction-container{
    height: 100%;
    align-content: center;
    padding: 0px 5% 10%;
    text-align: center;
    font-family: monospace;
    background-color: #22181c;
}

.contact-icon{
    margin-bottom: 60px;
}

p{
    text-align: left;
    padding: 10px;
    color: #FFFFFA;
    font-size: 14px;
}

section{
    padding-top: 50px;
    display: flex;
    justify-content: space-between;
}

#self{
    width: 33%;
    height: auto;
    margin-top: 18%;
}
#speech-bubble{
    width: 51%;
    height: auto;
    z-index: -1;
}

@media only screen and (max-width: 425px){
    .icon{
        width: 80%;
        height: auto;
        padding-top: 20px;
    }
    .icon-cluster{
        display: flex;
        justify-content: space-evenly;
    }
    section{
        flex-direction: column;
        padding-top: 0px;
    }

}
.media-container{
    width: 100%;
    height: auto;
    background-color: #5E5759;
    outline: solid #BDD990
}

.background-and-border{
    width: 100%;
    height: auto;
    padding: 1px 6px;
    margin-top: 5px;
}

.video{
    height: 730px;
    background-color: #5E5759;
    outline: solid #BDD990;
    padding: 4px 6px;

}
@media only screen and (max-width: 1024px){
    .video{
        height: 523px;
    }
}
@media only screen and (max-width: 768px){
    .video{
        height: 395px;
    }
}
@media only screen and (max-width: 425px){
    .video{
        height: 220px;
        padding: 4px 4px;
    }
}
@media only screen and (max-width: 375px){
    .video{
        height: 194px;
    }
}
@media only screen and (max-width: 320px){
    .video{
        height: 165px;
    }
}
