.construction-text{
    font-family: monospace;
    color: #BDD990;
}

.icon-cluster{
    display: flex;
    justify-content: center;
}

.icon{
    width: 100%;
    height: auto;
    padding: 25px;
}

.construction-container{
    height: 100%;
    align-content: center;
    padding: 0px 5% 10%;
    text-align: center;
    font-family: monospace;
    background-color: #22181c;
}

.contact-icon{
    margin-bottom: 60px;
}

p{
    text-align: left;
    padding: 10px;
    color: #FFFFFA;
    font-size: 14px;
}

section{
    padding-top: 50px;
    display: flex;
    justify-content: space-between;
}

#self{
    width: 33%;
    height: auto;
    margin-top: 18%;
}
#speech-bubble{
    width: 51%;
    height: auto;
    z-index: -1;
}

@media only screen and (max-width: 425px){
    .icon{
        width: 80%;
        height: auto;
        padding-top: 20px;
    }
    .icon-cluster{
        display: flex;
        justify-content: space-evenly;
    }
    section{
        flex-direction: column;
        padding-top: 0px;
    }

}