.title{
    left: 50px;
    color: #BDD990;
    text-align: left;
}
.header-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-bottom: 1px solid #BDD990;
    height: 100px;
    width: 100%;
    padding: 0 5%;
    position: fixed;
    background-color: #22181C;
}
.nav-container{
    display: flex;
    justify-content: flex-end;
    text-align: right;
    color: aliceblue;
    padding: 0;
}

a:focus, a:hover {
    color:  #BDD990;
    text-decoration: none;
}
.small-menu, .large-menu {
    background-color: #22181b;
    border-bottom: solid 1px #bcd990;
}
ul{
    list-style: none;
    margin-bottom: 0;
}

#nm-logo{
    height: 199px;
    margin: -38px -54px;
    padding: 0;
}

.a-text{ 
    display: flex;
    justify-content: space-evenly;
    margin: 28px 0px 18px 2px;
    padding: 12px 0 0 0px;
    font-size: 1.8rem;
    text-decoration: none;
    color: white;
    background-color: #5e5759;
    width: 100px;
    height: 50px;
    border: solid 1px #BDD990;
    font-family: monospace;
}
.Navbar{
    display: flex;
    justify-content: space-between;
    padding: 0 5%;
}
.ntmdev-logo{
    font-size: 6rem;
    font-family: monospace;
    padding-top: 8px;
    color: #BDD990;
}

@media only screen and (max-width: 500px){
    #nm-logo{
        height: 54px;
    }
    .header-container{
        height: 70px;
        height: 70px;
        justify-content: center;
        padding: 0;
    }
    .a-text{
        width: 300px;
        margin: 5px;
    }
    .nav-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: right;
        padding: 2% 2%;
        color: aliceblue;
        background: #22181b;
        outline: solid 1px #bcd990;
    }
    .manu-container{
        width: 100%;
    }
    .Navbar{
        display: flex;
        flex-direction: column;
    }
}
@media only screen and (max-width: 900px){
    .ntmdev-logo {
        font-size: 4rem;
        font-family: monospace;
        padding-top: 20px;
    }
}